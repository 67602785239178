import { render, staticRenderFns } from "./CreateSkuDialog.vue?vue&type=template&id=a9754888&"
import script from "./CreateSkuDialog.vue?vue&type=script&lang=ts&"
export * from "./CreateSkuDialog.vue?vue&type=script&lang=ts&"
import style0 from "./CreateSkuDialog.vue?vue&type=style&index=0&id=a9754888&prod&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports