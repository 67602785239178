









































































import { Component, Prop, Vue } from 'vue-property-decorator'
import DirectUpload from '@/components/DirectUpload/index.vue'
import { handleColorImage } from '@/utils/color-img'
import { GiftCard } from '@/models'

@Component({
  name: 'GiftCardVisualSettings',
  components: {
    DirectUpload
  }
})
export default class extends Vue {
  @Prop() private giftCard!: GiftCard

  private barcodeSymbologyOptions = [
    { name: 'Code 128', value: 'code128' },
    { name: 'EAN 13', value: 'ean13' }
  ]

  private async getColorLogoBanner() {
    const color = await handleColorImage(this.giftCard.logo)
    this.$emit('logoPrimaryColor', color)
  }

  private uploadCallbackLogo(file: any) {
    this.$emit('updateLogo', file.blob.signed_id)
  }

  private uploadCallbackLogoSquare(file: any) {
    this.$emit('updateLogoSquare', file.blob.signed_id)
  }
}
