




















































import { Component, Prop, Vue } from 'vue-property-decorator'
import { GiftCard } from '@/models'

@Component({
  name: 'GiftCardNetworkSettings'
})
export default class extends Vue {
  @Prop() private giftCard!: GiftCard
}
