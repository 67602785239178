







































import { Component, Prop, Vue } from 'vue-property-decorator'
import { GiftCard } from '@/models'

@Component({
  name: 'BrandViewUse'
})
export default class extends Vue {
  @Prop() public giftCard!: GiftCard

  private condPaiement = true
}
