










































































































import { Component, Prop, Vue } from 'vue-property-decorator'
import { GiftCard } from '@/models'
import VueTrix from 'vue-trix'

@Component({
  name: 'GiftCardSettings',
  components: {
    VueTrix
  }
})
export default class extends Vue {
  @Prop() private giftCard!: GiftCard

  hide: any = {
    table: true
  }

  set howToUseOnline(value: string) {
    this.$emit('howToUseOnline', value)
  }

  get howToUseOnline(): string {
    return this.giftCard.howToUseOnline
  }

  set howToUseOffline(value: string) {
    this.$emit('howToUseOffline', value)
  }

  get howToUseOffline(): string {
    return this.giftCard.howToUseOffline
  }

  set termOfUse(value: string) {
    this.$emit('termOfUse', value)
  }

  get termOfUse(): string {
    return this.giftCard.termOfUse
  }
}
