



























































































import { Component, Vue } from 'vue-property-decorator'
import DirectUpload from '@/components/DirectUpload/index.vue'
import { IValidationError } from 'spraypaint/lib-esm/validation-errors'
import { GiftCard } from '@/models'
import VueTrix from 'vue-trix'

@Component({
  components: {
    GiftCardNetworkSettings: () => import('./components/GiftCardNetworkSettings.vue'),
    GiftCardUsageRules: () => import('./components/GiftCardUsageRules.vue'),
    GiftCardSettings: () => import('./components/GiftCardSettings.vue'),
    GiftCardVisualSettings: () => import('./components/GiftCardVisualSettings.vue'),
    SkuTable: () => import('./components/SkuTable.vue'),
    DirectUpload,
    VueTrix
  }
})
export default class extends Vue {
  private activeTab = '1'
  public giftCard = new GiftCard({ faceValueAvailable: [] })
  public brandLoading = true

  private async getBrandById() {
    const { data } = await GiftCard.selectExtra(['logoBlob', 'logoSquareBlob']).find(this.$route.params.id)

    this.giftCard = data
    this.brandLoading = false
  }

  async updateData() {
    await this.giftCard.save()

    if (Object.keys(this.giftCard.errors).length > 0) {
      this.printErrors(this.giftCard.errors)
    } else {
      this.$notify({
        title: 'GiftCard Updated',
        message: 'GiftCard successfully updated',
        type: 'success',
        duration: 2000
      })
    }
  }

  async createData() {
    await this.giftCard.save({})

    if (Object.keys(this.giftCard.errors).length > 0) {
      this.printErrors(this.giftCard.errors)
    } else {
      this.$notify({
        title: 'GiftCard created',
        message: 'GiftCard successfully created',
        type: 'success',
        duration: 2000
      })
    }
  }

  private printErrors(errors: any) {
    const e: (IValidationError<GiftCard> | undefined)[] = Object.values(errors)
    for (const error of e) {
      if (error) {
        this.$notify({
          title: error.title,
          message: error.fullMessage,
          type: 'error',
          duration: 5000
        })
      }
    }
  }

  private uploadCallback(file: any) {
    this.giftCard.logo = file.blob.signed_id
  }

  created() {
    if (this.$route?.params?.id) this.getBrandById()
    else this.brandLoading = false
  }

  get isCreate() {
    return this.$route.name === 'GiftCardCreate'
  }
}
