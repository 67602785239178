























































import { Component, Vue } from 'vue-property-decorator'
import Pagination from '@/components/Pagination/index.vue'
import { GiftCard } from '@/models/index'
import { IValidationError } from 'spraypaint/lib-esm/validation-errors'

@Component({
  name: 'SkusTable',
  components: {
    Pagination
  }
})
export default class extends Vue {
  private tableKey = 0
  private list: GiftCard[] = []
  private total = 0
  private listLoading = true
  private listQuery = {
    page: 1,
    limit: 20,
    name: undefined
  }

  private async getList() {
    this.listLoading = true
    const { data, meta } =
        await GiftCard
          .where({ name: { prefix: this.listQuery.name } })
          .page(this.listQuery.page)
          .per(this.listQuery.limit)
          .order({ name: 'asc' })
          .stats({ total: 'count' }).all()

    this.list = data
    this.total = meta.stats.total.count
    this.listLoading = false
  }

  private handleFilter() {
    this.listQuery.page = 1
    this.getList()
  }

  private printErrors(errors: any) {
    const e: (IValidationError<GiftCard> | undefined)[] = Object.values(errors)
    for (const error of e) {
      if (error) {
        this.$notify({
          title: error.title,
          message: error.fullMessage,
          type: 'error',
          duration: 5000
        })
      }
    }
  }

  private async handleDelete(data: GiftCard, index: number) {
    await data.destroy()

    if (Object.keys(data.errors).length > 0) {
      this.printErrors(data.errors)
    } else {
      this.$notify({
        title: 'Success',
        message: 'Delete Successfully',
        type: 'success',
        duration: 2000
      })
      this.list.splice(index, 1)
      this.total -= 1
    }
  }

  created() {
    this.getList()
  }

  private handleAction(command: any, giftCard: GiftCard) {
    if (command === 'edit') this.$router.push({name: 'GiftCardEdit', params: {id: giftCard.id}})
  }
}
