




































































import { Component, Prop, Vue } from 'vue-property-decorator'
import Pagination from '@/components/Pagination/index.vue'
import CreateSkuDialog from './CreateSkuDialog.vue'
import { GiftCard, Sku } from '@/models'
import { IValidationError } from 'spraypaint/lib-esm/validation-errors'

@Component({
  name: 'SkuTable',
  components: {
    Pagination,
    CreateSkuDialog
  }
})
export default class extends Vue {
  @Prop() public giftCard!: GiftCard

  private tableKey = 0
  private list: Sku[] = []
  private total = 0
  private listLoading = true
  private visible = false
  private dialogStatus = ''
  private dialogData = null
  private indexData = 0
  private listQuery = {
    page: 1,
    limit: 20
  }

  private async getList() {
    this.listLoading = true
    const { data, meta } =
        await Sku
          .where({ giftCardId: this.giftCard.id })
          .page(this.listQuery.page)
          .per(this.listQuery.limit)
          .stats({ total: 'count' })
          .selectExtra(['cardImageBlob'])
          .all()

    this.list = data
    this.total = meta.stats.total.count
    this.listLoading = false
  }

  updateTable(sku: Sku) {
    this.list.splice(this.indexData, 1, sku)
    this.dialogData = null
  }

  pushTable(sku: Sku) {
    this.list.splice(0, 0, sku)
    this.dialogData = null
  }

  private printErrors(errors: any) {
    const e: (IValidationError<Sku> | undefined)[] = Object.values(errors)
    for (const error of e) {
      if (error) {
        this.$notify({
          title: error.title,
          message: error.fullMessage,
          type: 'error',
          duration: 5000
        })
      }
    }
  }

  private handleCreate() {
    this.dialogStatus = 'create'
    this.visible = true
  }

  private handleUpdate(row: any, index: number) {
    this.dialogData = row
    this.indexData = index
    this.dialogStatus = 'update'
    this.visible = true
  }

  private async handleDelete(data: Sku, index: number) {
    await data.destroy()

    if (Object.keys(data.errors).length > 0) {
      this.printErrors(data.errors)
    } else {
      this.$notify({
        title: 'Success',
        message: 'Delete Successfully',
        type: 'success',
        duration: 2000
      })
      this.list.splice(index, 1)
      this.total -= 1
    }
  }

  private async handleDiscard(data: Sku) {
    data.isDiscarded() ? data.undiscard() : data.discard()
  }

  created() {
    this.getList()
  }
}
